import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import Quote from 'src/components/desktop/quote/quote';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_5/sub_1/5-1-1-bg1.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
    scenelayer1: file(relativePath: { eq: "chapter_5/sub_1/5-1-1-bg2.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  modal: file(relativePath: { eq: "chapter_5/sub_1/5-1-1-modal1.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  }
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scenelayer
          scene={2}
          move={{ x: -10, y: -20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
        />
        <Mordo
          flexi
          scene={2}
          move={{ x: 10, y: 20 }}
        >
          <div css={{
            alignSelf: 'center', width: '100%', margin: '5% 35% 0 auto',
          }}
          >
            <Quote fontColor="white" quoteText={query.allMdx.edges[0].node.body} quoteAuthor={query.allMdx.edges[0].node.frontmatter.author} modalImage={assetQuery.modal.childImageSharp.fixed} />


          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
